// easing gradient generated by https://larsenwork.com/easing-gradients/
export const easingGradient = `
    hsl(0, 0%, 100%) 0%,
    hsla(0, 0%, 100%, 0.987) 8.1%,
    hsla(0, 0%, 100%, 0.951) 15.5%,
    hsla(0, 0%, 100%, 0.896) 22.5%,
    hsla(0, 0%, 100%, 0.825) 29%,
    hsla(0, 0%, 100%, 0.741) 35.3%,
    hsla(0, 0%, 100%, 0.648) 41.2%,
    hsla(0, 0%, 100%, 0.55) 47.1%,
    hsla(0, 0%, 100%, 0.45) 52.9%,
    hsla(0, 0%, 100%, 0.352) 58.8%,
    hsla(0, 0%, 100%, 0.259) 64.7%,
    hsla(0, 0%, 100%, 0.175) 71%,
    hsla(0, 0%, 100%, 0.104) 77.5%,
    hsla(0, 0%, 100%, 0.049) 84.5%,
    hsla(0, 0%, 100%, 0.013) 91.9%,
    hsla(0, 0%, 100%, 0) 100%
`;
